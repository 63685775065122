import Swiper from 'swiper'
import { Navigation, Pagination, Autoplay, Controller, Grid, EffectCoverflow } from 'swiper/modules'
import global from 'site/global'

function QuickThumbsCarousel(sel, doRandomInitialSlide = true, maxTotal = 4, thumbType = '', spaceBetween = 20) {
    this.sel = sel
    this.quickThumbsSwiper = null
    this.self = this
    this.spaceBetween = spaceBetween
    this.id = null
    this.dataType = 'full-bleed-carousel-strip'
}

QuickThumbsCarousel.prototype.init = function () {
    let t = this
    t.id = $(this.sel).attr('data-dots-id')
    const swiperOptions= {
        modules: [Navigation, Pagination, Autoplay, EffectCoverflow],
        speed: 500,
        //autoplay: false,
        autoplay: {
            delay: 7000,
            disableOnInteraction: true,
        },
        effect: 'coverflow',
        coverflowEffect: {
            rotate: 5,
            stretch: 0,
            depth: 200,
            modifier: 1,
            slideShadows: false,
            scale: 0.50,
        },
        init: false,
        loop: true,
        //loopAdditionalSlides: 25,
        spaceBetween: 0,
        slidesPerView: 'auto',
        centeredSlides: true,
        slideToClickedSlide: false,
        // watchSlidesVisibility: true,
        // watchSlidesProgress: true,
        // touchMoveStopPropagation: true,
        // mousewheelControl: false,
        navigation: {
            nextEl: this.sel + "-arrows .swiper-button-next",
            prevEl: this.sel + "-arrows .swiper-button-prev",
        },
        // pagination: {
        //     enabled: true,
        //     clickable: true,
        //     el: this.sel + "-dots .dots",
        // },
    }

    this.quickThumbsSwiper = new Swiper(this.sel + " .quick-thumbs-swiper", swiperOptions)

    this.quickThumbsSwiper.on('init', function (a) {
        t.quickThumbsSwiper.slideToLoop(0, 0, false)
        t.quickThumbsSwiper.update()
    })

    this.quickThumbsSwiper.on('beforeInit', function (a) {
    })

    this.quickThumbsSwiper.on('slideChangeTransitionEnd', function (a) {
    })

    if (this.quickThumbsSwiper) {
        this.quickThumbsSwiper.init()
    }
}
export default QuickThumbsCarousel
